/* eslint-disable jsx-a11y/no-onchange */
import css from 'styled-jsx/css'
import 'cleave.js/dist/addons/cleave-phone.us'

import UsStates from 'assets/json/usStates.json'
import GooglePlaces from '@ui/modules/googlePlaces'
import { SyncedProp } from '@ui/hooks'
import { useNotiContext, REMOVE_ALL_AND_ADD } from '@notify'
import { useEffect } from 'react'
import { CustomerAddress } from '@libs/client/order/services'
// import { useIsChangeAddress } from '@libs/client'

interface IAddressForm extends React.AllHTMLAttributes<HTMLDivElement> {
  label: string
  errors: Types.AddressErrors
  isPaidOrder: boolean
  addressSyncedProp: SyncedProp<CustomerAddress>
  showEmail?: boolean
  version?: string
}

const usStates = UsStates

export default function AddressForm({
  label = 'Address',
  errors = {},
  isPaidOrder = false,
  addressSyncedProp,
  version = 'v144',
  ...props
}: IAddressForm) {
  const [syncedAddress, setSyncedAddress] = addressSyncedProp
  const { notiDispatch } = useNotiContext()
  // const [, setIsChangeAddress] = useIsChangeAddress()
  useEffect(() => {
    new GooglePlaces('js-address-input', onPlaceChanged)
  }, [])

  function onPlaceChanged(addr: CustomerAddress) {
    if (!usStates.includes(addr.state || '')) {
      if (addr.state) {
        notiDispatch({
          type: REMOVE_ALL_AND_ADD,
          payload: {
            content: `This item does not ship to ${addr.state}`,
            type: 'is-danger'
          }
        })
        setSyncedAddress((prevSyncedAddress) => {
          return {
            ...prevSyncedAddress,
            address1: ''
          }
        })
      }
      return null
    }
    setSyncedAddress((prevSyncedAddress) => {
      return {
        ...prevSyncedAddress,
        ...addr
      }
    })
  }

  function acceptNumber(event: React.ChangeEvent<HTMLInputElement>) {
    const phoneInput = event.target.value
    let phone = ''
    let digitPhone = phoneInput.replace(/\D/g, '')
    if (digitPhone.length === 11 && digitPhone.startsWith('1')) {
      digitPhone = digitPhone.substring(1)
    }
    const x = digitPhone.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    if (x !== null) {
      phone = (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')).trim()
    }
    setSyncedAddress((prevSyncedAddress) => {
      return {
        ...prevSyncedAddress,
        phone
      }
    })
  }

  function acceptPostalCode(event: React.ChangeEvent<HTMLInputElement>) {
    const postalCodeInput = event.target.value
    setSyncedAddress((prevSyncedAddress) => {
      return {
        ...prevSyncedAddress,
        postal_code: postalCodeInput?.trim() ?? ''
      }
    })
  }

  function onCommonChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: keyof CustomerAddress
  ) {
    // setIsChangeAddress(true)
    const value = event.target.value
    setSyncedAddress((prevSyncedAddress) => {
      return {
        ...prevSyncedAddress,
        [field]: value
      }
    })
  }

  return (
    <div {...props} className={`address-form ${props?.className ? props.className : ''}`}>
      <style jsx global>
        {globalStyle}
      </style>
      <div className="mb-2 has-text-weight-medium">{label}</div>
      <div className="columns is-mobile is-variable is-1 m-0">
        <div className="column pt-0 pb-0 pl-0">
          <div className="field mb-3">
            <div className="control">
              <input
                value={syncedAddress.first_name ?? ''}
                name="firstName"
                className={`input ${errors.first_name ? 'is-danger' : ''}`}
                disabled={isPaidOrder}
                type="text"
                placeholder={version == 'v13' ? 'Enter first name' : 'First name'}
                onChange={(event) => onCommonChange(event, 'first_name')}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              <p className="help is-danger">{errors.first_name}</p>
            </div>
          </div>
        </div>
        <div className="column pt-0 pr-0 pb-0">
          <div className="field mb-3">
            <div className="control">
              <input
                value={syncedAddress.last_name ?? ''}
                name="lastName"
                className={`input ${errors.last_name ? 'is-danger' : ''}`}
                disabled={isPaidOrder}
                type="text"
                placeholder={version == 'v13' ? 'Enter last name' : 'Last name'}
                onChange={(event) => onCommonChange(event, 'last_name')}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              <p className="help is-danger">{errors.last_name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            id="js-address-input"
            value={syncedAddress.address1 ?? ''}
            name="address"
            className={`input ${errors.address1 ? 'is-danger' : ''}`}
            disabled={isPaidOrder}
            type="text"
            placeholder={version == 'v13' ? 'Enter address' : 'Address'}
            onChange={(event) => onCommonChange(event, 'address1')}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
          />
          <p className="help is-danger">{errors.address1}</p>
        </div>
      </div>
      <div className="field">
        <div className="control">
          <input
            value={syncedAddress.address2 ?? ''}
            name="apartment"
            className={`input ${errors.address2 ? 'is-danger' : ''}`}
            disabled={isPaidOrder}
            type="text"
            placeholder={version == 'v13' ? 'Enter apartment (optional)' : 'Apartment (option)'}
            onChange={(event) => onCommonChange(event, 'address2')}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
          />
          <p className="help is-danger">{errors.address2}</p>
        </div>
      </div>
      <div className="columns is-mobile is-variable is-1 m-0">
        <div className="column pt-0 pb-0 pl-0">
          <div className="field mb-3">
            <div className="control">
              <input
                value={syncedAddress.city ?? ''}
                name="city"
                className={`input ${errors.city ? 'is-danger' : ''}`}
                disabled={isPaidOrder}
                type="text"
                placeholder={version == 'v13' ? 'Enter city' : 'City'}
                onChange={(event) => onCommonChange(event, 'city')}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              <p className="help is-danger">{errors.city}</p>
            </div>
          </div>
        </div>
        <div className="column pt-0 pr-0 pb-0">
          <div className="field mb-3">
            <div className="control">
              {isPaidOrder && (
                <input
                  className="input disabled"
                  type="text"
                  value={syncedAddress.state ?? ''}
                  placeholder={version == 'v13' ? 'Select State' : 'State'}
                  name="state"
                  disabled
                  onChange={(event) => onCommonChange(event, 'state')}
                  onKeyPress={(e) => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                />
              )}
              {!isPaidOrder && (
                // eslint-disable-next-line react/jsx-no-comment-textnodes
                <div className="select">
                  <select
                    value={syncedAddress.state ?? ''}
                    name="state"
                    className={`${syncedAddress.state ? '' : 'placeholder'}`}
                    disabled={isPaidOrder}
                    // onBlur={(event) => onCommonChange(event, 'state')}
                    onChange={(event) => onCommonChange(event, 'state')}
                  >
                    <option value={''} disabled={true} hidden={true}>
                      {version == 'v13' ? 'Select State' : 'State'}
                    </option>
                    {usStates.map((state: string) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <p className="help is-danger">{errors.state}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-mobile is-variable is-1 m-0">
        <div className="column pt-0 pb-0 pl-0">
          <div className="field mb-3">
            <div className="control">
              <input
                value={syncedAddress.postal_code ?? ''}
                name="postalCode"
                className={`input ${errors.postal_code ? 'is-danger' : ''}`}
                disabled={isPaidOrder}
                type="tel"
                placeholder="Postal code"
                onChange={acceptPostalCode}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              <p className="help is-danger">{errors.postal_code}</p>
            </div>
          </div>
        </div>
        <div className="column pt-0 pr-0 pb-0">
          <div className="field mb-3">
            <div className="control">
              <input
                className="input disabled"
                type="text"
                placeholder="Country"
                value="United States"
                name="country"
                disabled
                // onChange={(event) => onCommonChange(event, 'country')}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="field">
        <div className="control has-prefix">
          <input
            value={syncedAddress.phone ?? ''}
            type="tel"
            name="phone"
            placeholder={version == 'v13' ? 'Enter phone number' : 'Phone number'}
            className={`input input__phone_number ${errors.phone ? 'is-danger' : ''}`}
            disabled={isPaidOrder}
            onInput={acceptNumber}
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault()
            }}
          />
          <span className="prefix">+1</span>
          <p className="help is-danger">{errors.phone}</p>
        </div>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .address-form {
    .select {
      display: block;
      height: auto;

      select {
        box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
        height: auto;
        padding: 15px 40px 15px 20px;
        width: 100%;
        white-space: nowrap;

        &.placeholder {
          color: #b5b5b5;
        }
      }

      &::after {
        border-color: var(--text) !important;
        opacity: 0.6 !important;
        border-bottom-width: 2px !important;
        border-left-width: 2px !important;
      }
    }

    .input {
      &.disabled {
        color: var(--text);
        opacity: 0.8;
      }
    }
  }

  .pac {
    &-item {
      font-size: 14px;
      height: auto;
      padding: 15px 20px;
    }

    &-icon {
      margin-right: 10px;
    }
  }
`
