import { CustomerAddress } from '@libs/client/order/services'
import getConfig from 'next/config'
import { importScript } from '@ui/helpers'

export default class GooglePlaces {
  constructor(elementId: string, onPlaceChanged: (place: CustomerAddress) => void) {
    this.init(elementId, onPlaceChanged)
  }

  // &loading=async

  private async init(elementId: string, onPlaceChanged: (place: CustomerAddress) => void) {
    const apiKey = getConfig().publicRuntimeConfig.googlePlacesApiKey || ''
    const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&libraries=places&callback=initMap`
    const scriptImported = await importScript(url)

    if (scriptImported) {
      if (window?.initMap) {
        window.initMap()
      } else {
        // @ts-ignore
        window.initMap = () => {
          const googlePlaces = (window.google as any).maps.places
          const input = document.getElementById(elementId)
          const autocomplete = new googlePlaces.Autocomplete(input)
          autocomplete.setComponentRestrictions({ country: 'us' })
          autocomplete.setFields(['address_component'])
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()
            const address = this.convertAddress(place.address_components)
            onPlaceChanged(address)
          })
        }
      }
    }
  }

  private convertAddress(addressComponents: any) {
    const address: Types.PlainObject = {}

    addressComponents?.forEach((item: any) => {
      const type = item.types[0]
      address[type] = item.long_name
    })

    return this.combineAddress(address)
  }

  private combineAddress(addr: any): CustomerAddress {
    return {
      address1: addr.street_number ? `${addr.street_number} ${addr.route}` : addr.route,
      city: addr.locality || addr.sublocality_level_1,
      state: addr.administrative_area_level_1,
      postal_code: addr.postal_code,
      country: addr.country
    }
  }
}
